<template>
  <user-dashboard-layout>
    <v-container>
      <v-row>
        <v-col><h1>Imported from XA</h1></v-col>
        <v-col>
          <v-select
            :items="srcItems"
            v-model="src"
          >

          </v-select>
        </v-col>
        <v-col>
          <v-btn
            x-large
            color="primary"
            @click="parseSftp"

          > manual parse
          </v-btn>
        </v-col>
      </v-row>


      <v-row v-if="reportParse">
        <v-col>
          <h2>Report sftp parse: </h2>
          <pre>
            {{ reportParse }}
          </pre>
        </v-col>
      </v-row>
      <v-row dense>
        <v-text-field
            dense
            outlined
            append-icon="mdi-magnify"
            label="Search"
            single-line
            v-model="search"
            hide-details

        ></v-text-field>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            v-if="table && table.length > 0"
            :headers="headers"
            :items="table"
            :items-per-page="15"
            class="elevation-1"
            item-key="id"
            :expanded.sync="expanded"
            show-expand
            dense
            :search="search"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
            <pre>
              {{ item.xa_data }}
            </pre>
              </td>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "@/layouts/UserDashboardLayout";
import XaImportApi from '@/api/XaImport';

export default {
  name: "ImportedIndex",
  components: {UserDashboardLayout},

  data: function () {
    return {
      table: [],
      expanded: [],
      srcItems: ['ASSIGMENT', 'STATUS','ESTIMATE'],
      src: 'ASSIGMENT',
      reportParse:null,
      search:'',
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    headers() {
      return [
        {"text": "id", "value": "id"},
        {"text": "src", "value": "src"},
        {"text": "path", "value": "path"},
        {"text": "carrierId", "value": "carrierId"},
        {"text": "sendersXNAddress", "value": "sendersXNAddress"},
        {"text": "transactionId", "value": "transactionId"},
        {"text": "claimNumber", "value": "claimNumber"},
        {"text": "xa_data", "value": "xa_data", "align": " d-none"},
        {"text": "Created at", "value": "created_at"},
        {"text": "Updated at", "value": "updated_at"}
      ]
    }
  },
  methods: {
    fetchData() {
      XaImportApi.all().then(resp => {
        this.table = resp.data;
      })
    },
    parseSftp() {

      window.console.log(this.src);
      this.reportParse = null
        window.axios.get('/api/sftp_and_parse/' + this.src).then(res => {
        window.console.log(res);
        this.reportParse = res.data
        this.fetchData()
      })
    }
  }

}
</script>

<style scoped>

</style>
